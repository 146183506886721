const DevData = [
    {
        id: 1,
        title: "Jednoduchý web",
        text:  "Vytvoření jednoduché webové stránky do 3 podstránek a designu podle vašich požadavků, optimalizace pro vyhledávače, vlastní doména, možnost redakčního systému",
        price: "6499 Kč",
        monthlyPrice: "499 Kč" ,
    },
    {
        id: 2,
        title: "Vícestránkový web",
        text:  "Vytvoření vícestránkové webovky a designu podle vašich požadavků, optimalizace pro vyhledávače, vlastní doména, možnost redakčního systému",
        price: "8499 Kč",
        monthlyPrice: "499 Kč" ,
    },
    {
        id: 3,
        title: "Jednoduchý web - Paušálně",
        text:  "Vytvoření jednoduché webové stránky a designu podle vašich požadavků, optimalizace pro vyhledávače, vlastní doména, možnost redakčního systému",
        price: "599 Kč / Měsíc",
        monthlyPrice: "499 Kč" ,
    },
    {
        id: 4,
        title: "Vícestránkový web - Paušálně",
        text:  "Vytvoření vícestránkové webovky a designu podle vašich požadavků, optimalizace pro vyhledávače, vlastní doména, možnost redakčního systému",
        price: "799 Kč / Měsíc",
        monthlyPrice: "499 Kč / Měsíc" ,
    },
    {
        id: 5,
        title: "Údržba Webu",
        text:  "Údržba stránky, vložení obsahu / vypracování obsahu",
        price: "--- Kč",
        monthlyPrice: "999 Kč / Měsíc" ,
    },

]

export default DevData