import { useState, useEffect } from 'react';
import WebSliderData from './data/WebSliderData';
import "./webSlider.css";

const WebSlider = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalID = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % WebSliderData.length); // Cyklí index
        }, 10000);

        return () => clearInterval(intervalID); // Čistí interval při unmountu
    }, []); // Prázdné pole znamená, že se spustí pouze při prvním renderu

    // Získání tří aktuálních položek na zobrazení
    const currentItems = [
        WebSliderData[index],
        WebSliderData[(index + 1) % WebSliderData.length], // Cyklí index
        WebSliderData[(index + 2) % WebSliderData.length], // Cyklí index
    ];

    const classNames = ["div-nonactive","div-active", "div-nonactive"]; // Přizpůsobení tříd pro zobrazení

    return (
        <div className="all-items">
            <div className="current-items">
                {currentItems.map((item, itemIndex) => {
                    const { id, imageBackground, image: IconComponent, title, text } = item; // Destrukturování objektu
                    return (
                        <div key={id} className={classNames[itemIndex]}>
                            <div className="background-image">
                                < img src={imageBackground} alt="background"/>
                            </div>
                            <div className="icons">
                                <IconComponent /> {/* Vykreslení ikony jako React komponenta */}
                            </div>
                            <div className="text-items">
                                <h2>{title}</h2>
                            <p>{text}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default WebSlider;