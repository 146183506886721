 import { useParams, Link } from "react-router-dom"
 import projectFirestore from "../config"
 import { useState,useEffect } from "react"
 import image1 from "../images/MarketingDataImages/marketing2.jpg"
 import image2 from "../images/MarketingDataImages/marketing1-3.jpg"
 import image3 from "../images/MarketingDataImages/marketing1-1.jpg"
 import image4 from "../images/MarketingDataImages/marketing1-2.jpg"
 import "./Marketing.css"
  
 const Marketing = () => {
    const [data, setData] = useState({})
    const [error, setError] = useState(false)

    const {firebaseID} = useParams()

    useEffect ( ( ) => {
        projectFirestore.collection("marketing").doc(firebaseID).get().then( (document) => {
            if (document.exists) {
                setData(document.data())
            } else {
                setError("Dokument nenalezen")
            }
        }).catch((err) => setError(err.message) )
    }, [firebaseID])


   return <div className="all-page">
        {error && <p>{error}</p>}
        <h2>{data.title}</h2>
 <div className="container">
     
    <div className="article">
        <img src={image1} alt="" />
    </div>
    <div className="article">
        
        <p>{data.text}</p>
    </div>
 </div>
<div className="container">
    <div className="article">
        <p>{data.text2}</p>
    </div>
    <div className="article">
        <img src={image2} alt="" />
    </div>
</div>
<div className="container">
    <div className="article">
    <img src={image3} alt="" />
    </div>
    <div className="article">
        <p>{data.text3}</p>
    </div>
</div>
<div className="container">
    <div className="article">
        <p>{data.text4}</p>
    </div>
    <div className="article">
    <img src={image4} alt="" />
    </div>
</div>

        <div className="home">
        <Link to="/">Domů</Link>
        </div>

    </div>

 }
 
 export default Marketing