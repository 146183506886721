import uvod1 from "../images/uvod1.jpg"
import Marketing1 from "../images/MarketingDataImages/marketing1.jpg"
import webdata from "../components/data/WebsiteData"
import WebSlider from "../components/WebSlider"
import MarketingData from "../components/MarketingData"
import Web from "../images/mainc.png"
import { Link } from "react-router-dom"




import "./Home.css"

const Home = () => {
  

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' }); // Hladký scroll
    }
  };

 
  return <main>
  <section className="home-sluzby">
  <div className="uvod-obrazky">
    {webdata.map((res) => {
      return <div key={res.id} className="uvodni-text">
          <img src={res.image} alt="" />
          <div className="hlavni-text">
          <h2>{res.title}</h2>
          <p>{res.text}</p>
          <button onClick={() => {scrollToSection(res.section)}}>Více</button>
          
          </div>
        </div>
    })}
    </div>
      <div className="web-dev" id="sluzby">
        <div className="web-popis">
          <h1>Prozkoumáme vaše specifické cíle a vytvoříme detailní strategický plán pro váš web</h1>
          <img src={uvod1} alt="" />
        </div>
        <div className="web-popis">
          <p>
          Nabízíme profesionální tvorbu webových stránek na míru vašim potřebám. Poskytujeme kompletní službu zahrnující konzultaci, návrh designu, programování, tvorbu obsahu, optimalizaci pro vyhledávače (SEO) a testování. Zabezpečíme váš web proti hrozbám a zajistíme bezproblémové nasazení a údržbu.<br /><br /> S naší pomocí získáte moderní, funkční a bezpečný web, který osloví vaše zákazníky a podpoří vaše podnikání.

          </p>
          <h2>Co můžete čekat:</h2>
          <div className="web-space">
            <div className="web-plan"><h5>✓</h5><p>Konzultace a plánování</p></div>
            <div className="web-plan"><h5>✓</h5><p>Inovativní vizuální design</p></div>
            <div className="web-plan"><h5>✓</h5><p>Pokročilý vývoj a integrace</p></div>
            <div className="web-plan"><h5>✓</h5><p>Tvorba obsahu</p></div>
            <div className="web-plan"><h5>✓</h5><p>SEO optimalizace</p></div>
            <div className="web-plan"><h5>✓</h5><p>Monitoring a pravidelná údržba</p></div>
          </div>
        </div>
       </div>
      <div><WebSlider /></div>
  </section>
  <section id="marketing">
    <div className="hlavni-nadpis">
    <h1>Komplexní marketingové služby pro Maximální úspěch</h1>
    </div>
  <div className="web-dev">
  
            <div className="web-popis">
            
          <p>
          Naše marketingové služby vám pomohou uspět v online světě. SEO zlepší viditelnost vašich stránek ve výsledcích vyhledávání, zatímco PPC reklama zajistí okamžitý přísun cíleného provozu. E-mail marketing udrží kontakt se zákazníky a posílí loajalitu, zatímco sociální média marketing zvýší povědomí o vaší značce. Obsahový marketing vytvoří hodnotný obsah pro vaše publikum, a analýza dat umožní optimalizovat strategie pro nejlepší výsledky.< br /><br /> Celkově vám tyto služby pomohou efektivně oslovit zákazníky a maximalizovat návratnost investic.
          </p>


        </div>
        <div className="web-popis img">
          <img src={Marketing1} alt="" className="marketing-img"/>
        </div>
       </div>
    <div className="marketing-odkazy"><MarketingData /></div>
    <div className="space"></div>
    
    <div className="web-link">
            <div className="web-link-popis">
                <h1>Podívejte se na náš zpracovaný web. Moderní design a intuitivní uživatelské rozhraní vám ukážou, jak dokážeme transformovat vaše myšlenky na digitální realitu.</h1>
            </div>
            <div className="web-link-popis">
                <Link to="https://beauty-rychnov.cz/" target="_blank" rel="noopener noreferrer">
                    <img src={Web} alt="web" />
                </Link>
            </div>
        </div>
  </section>
  </main>
}

export default Home