
const MarketingPrice = [
    { 
        title: "Jednotná hodinová sazba",
        text: "Za všechny nabízené služby, jako je SEO, PPC reklama, sociální média marketing a analýza dat, účtujeme jednotnou hodinovou sazbu. Tato sazba je vhodná pro menší projekty nebo pro úkoly, které nejsou pravidelné.",
        price: "600 Kč/hod",        
    },
    { 
        title: "Procento z rozpočtu",
        text: "U správy PPC reklam si účtujeme 10 % z měsíčního rozpočtu na reklamu. Tato částka zahrnuje komplexní správu a optimalizaci kampaní, aby byly co nejefektivnější.",
        price: "10% z Rozpočtu",        
    },

]

export default MarketingPrice