import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Pages/Home"
import Marketing from "./Pages/Marketing"
import Sluzby from "./Pages/Sluzby"
import Kontakt from "./Pages/Kontakt"
import SharedLayout from "./Pages/SharedLayout"
import CookiesPage from "./Pages/Cookies"
import ScrollToTop from "./components/ScrollToTop"


const App = () => {
  

  return <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={ <SharedLayout /> }>
          <Route path="/" index element={ <Home />} />
            <Route path="Marketing/:firebaseID" element={ <Marketing /> } />
          <Route path="Sluzby" element={ <Sluzby />} />
          <Route path="Kontakt" element={ <Kontakt />} />
          <Route path="CookiesPage" element={ <CookiesPage />} />
        </Route>
      </Routes>
  </BrowserRouter>
}

export default App