import "./Footer.css"
import { Link } from "react-router-dom"
import logo from "../images/logo.png"

const Footer = () => {
  return <footer>
    <div className="all-container">
    <Link to="/"><img src={logo} alt=" " /></Link>
      <h2>WebNexus.cz</h2>
    </div>
    <div className="all-container"> 
        <Link to="/">Home</Link>
        <Link to="Sluzby">Ceník</Link>
        <Link to="Kontakt">Kontakt</Link>
    </div>
    <div className="all-container">
      <h3>Kontaktujte nás!</h3>
      <p>info@webnexus.cz</p>

      
    </div>

  </footer>
}

export default Footer