
import firebase from "firebase/app";
import "firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyDrkwtqwCtFcNsBSArN6VrLyJeTgRYEruI",
  authDomain: "movies-project-6be18.firebaseapp.com",
  projectId: "movies-project-6be18",
  storageBucket: "movies-project-6be18.appspot.com",
  messagingSenderId: "913173002890",
  appId: "1:913173002890:web:b11936c69b9b6e2bef8b73"
};

//pocatecni inicializace
firebase.initializeApp(firebaseConfig)

//pocatecni initializace services
const projectFirestore = firebase.firestore()

export default projectFirestore
