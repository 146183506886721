import website from "../../images/website.png"
import marketing from "../../images/marketing.png"

const WebsiteData = [
    {
        id: 11,
        image: website,
        title:  "Web",
        text: "Postaráme se o vaše webové stránky, abyste se mohli soustředit na váše podnikání!",
        section: "sluzby",
    },
    {
        id: 12,
        image: marketing,
        title:  "Marketing",
        text: "Postaráme se o váš marketing, abyste se mohli soustředit na růst vašeho podnikání!",
        section: "marketing",
    },
]

export default WebsiteData