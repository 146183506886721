import React from 'react'
import Cookies from 'js-cookie'
import "./Cookies.css"


const CookiesPage = () => {
    const handleRemoveCookies = () => {
        Cookies.remove("cookieConsent")
        alert("Souhlas s cookies byl odstraněn.")
    }
  return <section className='cookies'>
    
    <h2 className='nadpis'>Jaké cookies zpracováváme</h2>
<h3 className='podnadpis'>Na našem webu používáme následující cookies:</h3>

<h3 className='podnadpis'>Cookie pro souhlas s cookies</h3>

<p className='text'>Účel: Pamatuje si, zda uživatel souhlasil s používáním cookies. Tato cookie je nastavena na 365 dní a zabraňuje opakovanému zobrazení banneru pro souhlas.
Sledovací cookies</p>


<p className='text'>Účel: Shromažďují anonymizované informace o tom, jak uživatelé interagují s naším webem, což nám pomáhá analyzovat výkon stránek a zlepšovat uživatelský zážitek.
Správa cookies</p>
<p className='text'>Pokud nesouhlasíte s používáním cookies, můžete nastavit svůj prohlížeč tak, aby cookies odmítal. Mějte na paměti, že pokud cookies zakážete, některé funkce webu nemusí fungovat správně.</p>
<button className="button-cookies" onClick={handleRemoveCookies}>Odebrat cookies</button>
  </section>
}

export default CookiesPage