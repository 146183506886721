import "./Navbar.css"
import { NavLink, Link } from "react-router-dom"
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import logo from "../images/logo.png"
import { useState } from "react";

const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false)


  return <header>
<div className="navbar">
        <div className="logo">
            <Link to="/"><img src={logo} alt=""/></Link>
            <button onClick={() => {setShowMenu(!showMenu)}}>
              {showMenu ? (<ImCross className="cross-icon" />) : (<GiHamburgerMenu className="hamburger-icon"/>) }</button>
            
            
            
        </div>
        <div className={`${showMenu ? "show" : "hide"}`}>
    <nav >
        <NavLink to="/" onClick={() => setShowMenu(false)}> Domů </NavLink><br />
        <NavLink to="Sluzby" onClick={() => setShowMenu(false)}> Ceník </NavLink><br />
        <NavLink to="Kontakt" onClick={() => setShowMenu(false)}> Kontakt </NavLink>
    </nav>
    </div>
</div>

  </header>
}

export default Navbar