import projectFirestore from "../config"
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "./MarketingData.css"




const MarketingData = () => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)

    useEffect(() => {
        projectFirestore.collection("marketing").get().then( (snapshot) => {
  
          if (snapshot.empty) {
            setError("Zadne veci k vypsani")
          } else {
            let result = []
            snapshot.docs.forEach((oneMarketing) => {
              result.push({id: oneMarketing.id, ...oneMarketing.data()})
            })
            setData(result)
          }
         }).catch( ( err) => {
            setError(err.message)
         })
    }, [])
  return  <div>
  {error && <p>error</p>}
  <div className="all-item">
    {data.map((oneitem) => {
      const { id, title } = oneitem;
      return (
        <div className="one-item" key={id}>
          <Link to={`/Marketing/${id}`}>
          <div>{title}</div>
          <div></div>
          </Link>
        </div>
      );
    })}
  </div>
</div>
}

export default MarketingData