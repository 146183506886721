import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./Kontakt.css";

const Kontakt = () => {
    const [to, setTo] = useState(""); // e-mail příjemce
    const [text, setText] = useState(""); // text zprávy
    const [phone, setPhone] = useState("")

    const sendEmail = (e) => {
        e.preventDefault();

        const templateParams = {
            to_email: "info@webnexus.cz", // Zde nastavte cílový e-mail
            from_email: to, // Odesílatel - e-mail od uživatele
            phone: phone,
            message: text, // Obsah zprávy
        };
        
        emailjs
            .send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID, // ID služby
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // ID šablony
                templateParams,
                process.env.REACT_APP_EMAILJS_USER_ID // ID uživatele
            )
            .then((response) => {
                console.log("Úspěšně odesláno!", response.status, response.text);
                alert("E-mail byl úspěšně odeslán!");
                // Resetování formuláře
                setTo("");
                setText("");
            })
            .catch((err) => {
                console.error("Chyba při odesílání:", err);
                alert("Chyba při odesílání e-mailu.");
            });
    };
    return (
        <section id="Kontakt">
            <div className="form-text">
                <h2>Nezávazná poptávka</h2>
            </div>
            <div className="form">
                <form onSubmit={sendEmail}>
                    <input
                        type="email"
                        placeholder="Zadejte svůj e-mail"
                        value={to}
                        onChange={(e) => setTo(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="Zadejte svůj telefon( nepovinné)"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        
                    />
                    <textarea
                        type="text"
                        placeholder="Zadejte zprávu"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        required
                    />
                    <button type="submit">Odeslat</button>
                </form>
            </div>
            <div className="kontaktni-udaje">
              <h1>Kontaktní údaje:</h1>
              <h2>Telefon:</h2>
              <p>+420 731041659</p>
              <h2>Email:</h2>
              <p>info@webnexus.cz</p>
            </div>
        </section>
    );
};

export default Kontakt;