import { TfiTimer, TfiSearch, TfiCheckBox, TfiLayoutAccordionList, TfiDashboard } from "react-icons/tfi"
import image1 from "../../images/WebSliderImages/rychlost.png"
import image2 from "../../images/WebSliderImages/prizpusobivost.jpg"
import image3 from "../../images/WebSliderImages/seo.png"
import image4 from "../../images/WebSliderImages/organizace.png"
import image5 from "../../images/WebSliderImages/testovani.png"


const WebSliderData = [
    {
        id: 1,
        imageBackground: image1,
        image: TfiTimer,
        title: "Rychlost",
        text: "Webové stránky se vyznačují rychlou odezvou a plynulou interakcí na uživatelské akce",
    },
    {
        id: 2,
        imageBackground: image2,
        image: TfiCheckBox ,
        title: "Přizpůsobivost",
        text: "Stránky se snadno přizpůsobují všem zařízením",
    },
    {
        id: 3,
        imageBackground: image3,
        image: TfiSearch ,
        title: "Optimalizace pro Vyhledávač",
        text: "Webové stránky jsou optimalizovány pro snadné nalezení ve vyhledávačích",
    },
    {
        id: 4,
        imageBackground: image4,
        image: TfiLayoutAccordionList ,
        title: "Dobrá organizace dat",
        text: "Uživatelé mohou snadno spravovat a aktualizovat informace na stránkách",
    },
    {
        id: 5,
        image: TfiDashboard ,
        imageBackground: image5,
        title: "Možnost testování",
        text: "Jednotlivé části stránek lze snadno a rychle otestovat",
    },


];


export default WebSliderData