import data from "../components/data/DevData"
import MarketingData from "../components/data/MarketingPrice"
import "./Sluzby.css"

const Sluzby = () => {
  return <section>
    <div className="nadpis"><h1>WEB</h1> </div>
    <div className="sluzby-clanky"> 
          
    { data.map((response) => {
      return <article className="one-article" key={response.id}>
        <h3>{response.title}</h3>
        <p>{response.text}</p>
          <div className="price">
            <h1>Cena: {response.price}</h1>
            <h1>Měsíční údržba: {response.monthlyPrice}</h1>
          </div>
      </article>
    })}
    </div>
    <div className="nadpis"><h1>MARKETING</h1> </div>
    <div className="sluzby-clanky">
      {MarketingData.map((response, index) => {
        return <article className="one-marketing-article" key={index}>
          <h3>{response.title}</h3>
          <p>{response.text}</p>
          <h3>{response.price}</h3>
        </article>
      })}
    </div> 
    
  </section>
}

export default Sluzby