import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = () => {
    const { pathname } = useLocation(); // Získání cesty pomocí useLocation

    useEffect(() => {
        window.scrollTo(0, 0); // Posun na začátek stránky
    }, [pathname]); // Sledujeme změny cesty

    return null; // Komponenta nic nevykresluje
};

export default ScrollToTop;